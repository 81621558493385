<template>
  <div class="web-container">
    <!--页面顶部-->
    <div class="content-container page-header">
      <div class="flex flex-between">
        <div class="web-title flex flex-start">
          <img :src="webConfig.logoUrl" alt="" class="logo">
          <div class="title">
            <div class="title-school">{{ webConfig.schoolName }}</div>
            <div class="title-name">{{ webConfig.systemName }}</div>
          </div>
        </div>
        <!--导航列表-->
        <el-menu :default-active="navIndex" class="web-nav" mode="horizontal"
                 :active-text-color="'#4093f9'" @select="selectNav">
          <el-menu-item index="1">中心介绍</el-menu-item>
          <el-menu-item index="2">项目介绍</el-menu-item>
          <el-menu-item index="3" v-if="false">教学团队</el-menu-item>
          <el-menu-item index="4">友情链接</el-menu-item>
          <el-menu-item index="5" style="color: #4093f9">体验教学系统</el-menu-item>
        </el-menu>
      </div>
    </div>
    <!--轮播图-->
    <div class="focus-container ">
      <el-carousel :interval="6000" height="420px">
        <el-carousel-item v-for="item in webConfig.focusList" :key="item" style="text-align: center">
          <el-image
              style="width: 670px;height:420px;"
              :src="item"
              fit="fill"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--中心介绍-->
    <a href="#" name="center"></a>
    <div class="li-container content-container">
      <el-divider content-position="center" class="content-title">中心介绍</el-divider>
      <div class="content-html html-view">
        <p>
          思想政治理论课是落实立德树人根本任务的关键课程，是夯实青少年思想基础的重要阵地，思政课建设不仅关乎人才的培养，更与国家的前途命运紧密相连。党的十八大以来，以习近平同志为核心的党中央高度重视思政课建设发展。习近平总书记指出：“‘大思政课’我们要善用之，一定要跟现实结合起来。”</p>
        <p>
          根据习近平总书记关于思政课建设的系列重要指示批示精神和教育部等10部门印发的《全面推进“大思政课”建设的工作方案》等系列文件精神，2022年9月，北京青年政治学院“大思政课”虚拟仿真体验教学中心成立，旨在利用现代信息技术全面提升思政教学质量，努力培养担当民族复兴大任的时代新人。</p>
        <p>
          中心依托建设单位——北京青年政治学院，其前身为1956年成立的北京市团校，1986年7月，改革开放的总设计师邓小平同志亲笔题写院名，对学院发展寄予厚望。在六十多年的建设发展中，学院始终坚持服务首都经济社会发展和共青团事业发展的方向，为首都的建设和发展培养输送了一大批人文素养好、有一技之长、社会责任感强的高素质实用型技能人才。</p>
        <p>
          中心依托北京青年政治学院长期以来构建的“青年政治”特色思政教育品牌，利用物联网、云计算、人工智能等新一代信息技术，大力整合教师资源、争取社会资源、完善硬件资源，将虚拟仿真技术引入思想政治教育教学改革，通过“沉浸式、体验式、互动式”教学方式，充分调动学生学习积极性、主动性、参与性，推动学校思想政治课教学与现代信息技术有机融合。现已上线的思政虚拟仿真实验有：中共一大、红色金融、红色物流、血战湘江、巧渡金沙江。用马克思主义的立场、观点和方法，为青年人讲好党的百年历史、讲好中华文化与马克思主义之间的联系，阐释中国精神、中国价值、中国力量，让青年学子明白文化自信与中国特色社会主义道路自信、理论自信、制度自信的关系。</p>
        <p>
          中心正紧紧围绕立德树人根本任务，以教育数字化推进“大思政课”协同育人，强化政治、青年和人文特色，推进产教融合、校企合作，努力把学院建设成为全国一流高等职业教育院校和全国一流团青教育培训与研究基地，建设成为开放型、有特色、高水平的青年人大学。</p>
      </div>
    </div>
    <!--项目介绍-->
    <a href="#" name="experiment"></a>
    <div class="content-container experiment-container">
      <el-divider content-position="center" class="content-title">项目介绍</el-divider>
      <div class="experiment-list">
        <div class="sub-list flex flex-start flex-wrap">
          <div class="sub-li" v-for="(experimentItem,experimentIndex) in experiments.list">
            <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                 v-if="experimentItem.product_iconLeft">
            <div class="bg">
              <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'">
            </div>
            <div class="info">
              <img class="avatar" :src="experimentItem.product_icon" alt="">
              <div class="flex flex-dr flex-center">
                <div class="title">{{ experimentItem.name }}</div>
                <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                <!--最多3行 每行18字 第三行超出省略号显示-->
                <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                <div class="buttons">
                  <a class="button" href="javascript:void(0)"
                     @click="ExperimentMethods().clickViewExperiment(experimentItem)">查看详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--产品介绍弹窗-->
    <div v-if="experimentInfo.dialog" class="my-dialog-container flex flex-dr flex-center experiment-dialog">
      <div class="mask" @click="ExperimentMethods().closeExperimentDialog()"></div>
      <div class="dialog-box">
        <div class="bg"></div>
        <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon"
             @click="ExperimentMethods().closeExperimentDialog()">
        <div class="info-box">
          <div class="header-box flex flex-start">
            <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
            <div class="name-box">
              <div class="name">{{ experimentInfo.experiment.name }}</div>
              <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
            </div>
            <div>

            </div>
          </div>
          <div class="description-box">
            <div class="title">实验简介：</div>
            <div class="content">
              <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
            </div>
          </div>
          <div class="view-box flex flex-between">
            <div class="video-box" v-if="experimentInfo.experiment.product_video">
              <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                     webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                     class="video1 video"
                     draggable="true"></video>
              <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                   @click="e=>ExperimentMethods().playVideo(e)">
            </div>
            <el-image v-if="!experimentInfo.experiment.product_video" class="video-box-no" fit="cover"
                      :src="experimentInfo.experiment.product_info_img"
                      :preview-src-list="experimentDialogPicList"></el-image>
            <div class="img-box flex flex-around flex-wrap">
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_1"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_2"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_3"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
              <el-image
                  class="img"
                  fit="cover"
                  :src="experimentInfo.experiment.product_info_img_4"
                  :preview-src-list="experimentDialogPicList">
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--团队介绍-->
    <a href="#" name="member"></a>
    <div class="member-container content-container" v-if="false">
      <el-divider content-position="center" class="content-title">教学团队</el-divider>
      <div class="member-list">
        <el-card class="box-card" style="margin-bottom: 20px" shadow="hover">
          <div slot="header" class="flex flex-center">
            <span>思德法教研室</span>
          </div>
          <div class="avatar-list flex flex-start flex-wrap">
            <div class="avatar-li" v-for="(item,index) in memberList[0]['list']" @click="clickMember(item,index)">
              <el-image
                  style="width: 200px; height: 200px"
                  :src="item.avatar"
                  fit="scale-down"></el-image>
              <div class="name">{{ item.name }}（{{ item.duty }}）</div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card" shadow="hover">
          <div slot="header" class="flex flex-center">
            <span>概论教研室</span>
          </div>
          <div class="avatar-list flex flex-start flex-wrap">
            <div class="avatar-li" v-for="(item,index) in memberList[1]['list']" @click="clickMember(item,index)">
              <el-image
                  style="width: 200px; height: 200px"
                  :src="item.avatar"
                  fit="scale-down"></el-image>
              <div class="name">{{ item.name }}（{{ item.duty }}）</div>
            </div>
          </div>
        </el-card>
        <el-tabs tab-position="left" v-if="false">
          <el-tab-pane label="">
            <div class="avatar-list flex flex-start flex-wrap">
              <div class="avatar-li" v-for="(item,index) in memberList[0]['list']" @click="clickMember(item,index)">
                <el-image
                    style="width: 200px; height: 200px"
                    :src="item.avatar"
                    fit="scale-down"></el-image>
                <div class="name">{{ item.name }}（{{ item.duty }}）</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="概论教研室">
            <div class="avatar-list flex flex-start flex-wrap">
              <div class="avatar-li" v-for="(item,index) in memberList[1]['list']" @click="clickMember(item,index)">
                <el-image
                    style="width: 200px; height: 200px"
                    :src="item.avatar"
                    fit="scale-down"></el-image>
                <div class="name">{{ item.name }}（{{ item.duty }}）</div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
        :title="member.info.name"
        :visible.sync="member.dialog"
        :close-on-click-modal="true"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-descriptions direction="vertical" :column="4" border class="member-dialog">
          <el-descriptions-item label="现任职务">{{ member.info.duty }}</el-descriptions-item>
          <el-descriptions-item label="主授课程">{{ member.info.course }}</el-descriptions-item>
          <el-descriptions-item label="研究方向">{{ member.info.direction }}</el-descriptions-item>
          <el-descriptions-item label="主要社会兼职">{{ member.info.socialDuty }}</el-descriptions-item>
          <el-descriptions-item label="简历" :span="4">
            <div v-html="member.info.history" class="word-hr history"></div>
          </el-descriptions-item>
          <el-descriptions-item label="承担课题/研究成果" :span="4">
            <div v-html="member.info.success" class="word-hr success"></div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
    <!--友情链接-->
    <a href="#" name="link"></a>
    <div class="link-container content-container">
      <el-divider content-position="center" class="content-title">友情链接</el-divider>
      <div class="list flex flex-around flex-wrap">
        <div v-for="item in linkConfig.list" v-if="item.img" @click="clickLink(item)">
          <el-image :src="item.img" alt="" class="li" fit="contain"></el-image>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!--底部-->
    <div class="page-footer">
      <div class="info-container content-container">
        <div class="infos">
          <img :src="webConfig.logoUrl" alt="" class="logo">
          <p>{{ webConfig.webName }}</p>
          <p>主办：{{ webConfig.colleageName }}</p>
          <span>技术支持：成都智云鸿道信息技术有限公司<i class="blank"></i>薪火印记（北京）文化发展有限公司</span>
          <span style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">
            &nbsp;&nbsp;&nbsp;&nbsp;{{
              webConfig.beiAnText
            }}</span>
        </div>
      </div>
    </div>

    <template>
      <el-backtop :bottom="50"></el-backtop>
    </template>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import {H5_URL} from "@/model/ConfigModel";
import {isMobile} from "@/utils/common";
import {OfficialWebExperimentModel} from "@/model/erp/OfficialWebExperimentModel";

export default {
  name: "web_cqcsglzyxy",
  directives: {
    elDragDialog
  },
  data() {
    return {
      window: window,
      navIndex: "",
      webConfig: {
        "logoUrl": "http://resouce.cdzyhd.com/5f1ca55d-9438-4515-838d-53ee0f51c920.png",
        "schoolName": "北京青年政治学院",
        "systemName": "大思政课虚拟仿真体验教学中心",
        "colleageName": "北京青年政治学院马克思主义学院",
        "focusList": [
          "http://resouce.cdzyhd.com/1311242c-0f9f-451b-83bb-9daa02bdcee6.jpg",
          "http://resouce.cdzyhd.com/904d1e82-3731-448f-8abe-a07b04d20551.jpg",
          "http://resouce.cdzyhd.com/7fc4f949-d94a-4373-81a6-486c0bcd945f.jpg",
          "http://resouce.cdzyhd.com/beec03f3-6e8f-435c-8792-85d6882a4230.jpg"
        ],
        "webName": "北京青年政治学院大思政课虚拟仿真体验教学中心",
        beiAnText: "蜀ICP备19034784号-1"
      },
      introduceConfig: {
        "tabs": [{
          "name": "中心简介",
          "showNav": true,
          "text": "",
          "id": "1661264029372"
        }, {
          "name": "教学团队",
          "showNav": true,
          "text": "",
          "id": "1661264039431"
        }], "name": "中心介绍"
      },
      experiments: {
        list: []
      },
      experimentInfo: {
        dialog: false,
        experiment: {},
        list: []
      },
      memberActive: "1",
      memberList: [
        {
          list: []
        },
        {
          list: []
        },
      ],
      member: {
        dialog: false,
        info: {}
      },
      linkConfig: {
        list: [
          {
            "id": 1661270247796,
            "img": "http://resouce.cdzyhd.com/9fecfe08-8d44-4252-9d84-b174a6107d57.jpg",
            "name": "北京青年政治学院马克思主义学院",
            "url": "https://www.bjypc.edu.cn/mkszyxy/"
          },
          {
            "id": 1662270247796,
            "img": "http://resouce.cdzyhd.com/d23cf15e-2e72-4e9f-8777-b23f55fb824c.png",
            "name": "成都智云鸿道",
            "url": "http://www.cdzyhd.com/"
          }
        ]
      },
    }
  },
  created() {
    if (isMobile()) {
      window.location.href = H5_URL + this.$route.path
    }
  },
  mounted() {
    document.title = this.webConfig.webName
    // 判断是不是在移动端
    this.getExperimentConfig()
  },
  methods: {
    // 获取产品配置
    async getExperimentConfig() {
      // 要获取的实验列表，和自定义属性
      let experimentList = [
        {id: "1618478479657", name: "中共一大"},
        {id: "1618403277155", name: "红色金融"},
        {id: "1618403511881", name: "红色物流"},
        {id: "1618404762366", name: "血战湘江"},
        {id: "1618476671539", name: "巧渡金沙江"},
      ]
      let list = []
      for (let i = 0; i < experimentList.length; i++) {
        let id = experimentList[i]["id"]
        let experiment = await OfficialWebExperimentModel.getOneExperimentConfig(id)
        let experimentLast=Object.assign({},experiment,experimentList[i])
        // 合并自定义项
        list.push(experimentLast)
        this.$set(this.experiments, "list", list)
      }
    },
    // 点击首页导航
    selectNav(v) {
      switch (v) {
        case "1":
          window.location.href = "#center"
          break;
        case "2":
          window.location.href = "#experiment"
          break;
        case "3":
          window.location.href = "#member"
          break;
        case "4":
          window.location.href = "#link"
          break;
        case "5":
          window.location.href = "/login?schoolId=239675483795492864"
          break;
      }
    },
    // 点击团队成员
    clickMember(item, index) {
      this.$set(this.member, "info", item)
      this.member.dialog = true
    },
    // 点击友情链接
    clickLink(item) {
      window.open(item.url)
    },
    // 实验项目方法集
    ExperimentMethods() {
      let $this = this;
      return {
        clickViewExperiment(experiment) {
          $this.experimentInfo.dialog = true
          document.body.style.overflow = 'hidden'
          $this.experimentDialogEnterBtn = false
          $this.$set($this.experimentInfo, "experiment", experiment);
          $this.experimentDialogPicList = [
            experiment.product_info_img_1,
            experiment.product_info_img_2,
            experiment.product_info_img_3,
            experiment.product_info_img_4,
          ]
          if (!experiment.product_video && experiment.product_info_img) {
            $this.experimentDialogPicList.push(experiment.product_info_img)
          }
          setTimeout(() => {
            $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg})`)
          }, 100)
        },
        // 关闭实验弹窗
        closeExperimentDialog() {
          $this.experimentInfo.dialog = false
          document.body.style.overflow = ''
        },
        // 播放视频
        playVideo(e) {
          // this.videoPlay = true
          // document.querySelector("#v1").play()
          $this.experimentInfo.experimentVideoDialog = true
        }
      }
    }
  }
}
</script>
<style>
.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu--horizontal > .el-menu-item {
  margin-right: 20px !important;
  font-size: 18px !important;
  color: #777;
  letter-spacing: 2px;
  font-weight: 500;
}

.el-menu--horizontal > .el-menu-item:hover {
  transition: all 0.2s linear;
  transform: scale(1.1);
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 16px !important;
  letter-spacing: 2px;
}

.el-menu--horizontal .el-menu .el-menu-item {
  font-size: 14px !important;
  text-align: center;
}
</style>
<style scoped lang="less">
@import '../../style/app.less';

.web-container {
  //background-color: #f2f2f2;
}

.content-container {
  &:hover {
    .el-divider__text {
      color: #4093f9;
      font-size: 24px;
    }

  }
}

.content-title {
  margin-top: 40px;

  .el-divider__text {
    font-size: 22px;
    color: #777;
    font-weight: 450;
    text-align: center;
    margin-bottom: 20px;
  }

}

.page-header {
  width: 1280px;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 15px 0px;
  background-color: #fff;
  z-index: 1999;
  border-bottom: 1px solid #cecece;

  .web-title {


    .logo {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      cursor: pointer;
    }

    div.title {
      font-size: 20px;
      color: #666;
      font-weight: bold;
      text-align: center;

      div.title-school {
        margin-bottom: 8px;
      }

      div.title-name {

      }
    }
  }

  .web-nav {

  }
}

.focus-container {

}

.li-container {
  margin-bottom: 50px;

  .content-html {
    margin-top: 45px;

    text-indent: 2em;
    font-size: 15px;
    color: #666;
    line-height: 25px;
    letter-spacing: 1px;
  }
}

.experiment-list {
  margin-top: 55px;

  .sub-list {
    .sub-li {
      margin-bottom: 20px;
      margin-right: 40px;
      position: relative;

      &:nth-child(4n+4) {
        margin-right: 0px;
      }

      &:hover {
        .bg {
          img {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }

        .info {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .avatar {
            margin-top: -30px;
            opacity: 0;
          }


          .des-main {
            opacity: 0;
          }

          .title {
            margin-top: -30px;
            margin-bottom: 0px;

          }

          .des {
            display: -webkit-box;
          }

          .buttons {

            display: block;
          }
        }

      }


      img.left-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -10px;
        left: -10px;
        z-index: 10;
      }

      .bg {
        z-index: 1;
        width: 290px;
        height: 183px;
        overflow: hidden;
        position: relative;

        img {
          width: 290px;
          height: 183px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);

        }
      }

      .info {
        position: relative; // 解决margin-top负数 和同级div的z-index问题
        padding: 25px;
        height: 180px;
        z-index: 2;
        width: 270px;
        margin: 0 auto;
        margin-top: -60px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-bottom: 3px solid transparent;
        transition: all 0.2s linear;
        overflow: hidden;

        .avatar {
          display: inline-block;
          width: 60px;
          height: 60px;
          position: relative;
          margin-top: 0px;
          transition: all 0.2s linear;
          border-radius: 4px;
          box-shadow: 2px 2px 5px 1px #dedede;
        }

        .title {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 10px;
          transition: all 0.2s linear;
        }

        .des-main {
          transition: all 0.2s linear;
          color: #666;
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .des {
          font-weight: 300;
          font-size: 14px;
          display: none;
          color: #666;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .buttons {
          display: none;
          text-align: center;
          margin-top: 12px;
        }

        .button {
          width: 100px;
          line-height: 28px;
          border: 1px solid #d8d8d8;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;
          text-decoration: none;
          padding: 5px 20px;

          &:hover {
          }
        }

      }
    }
  }
}

.experiment-dialog {
  border-radius: 4px;
  z-index: 2000; // 解决被遮挡问题
  .dialog-box {
    border-radius: 4px;
  }

  .bg {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 330px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .close-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .info-box {
    padding: 0px 80px;
  }

  .header-box {
    position: relative;
    margin-top: -115px;

    .avatar {
      width: 130px;
      height: 130px;
      border-radius: 4px;
      box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
      margin-right: 20px;
    }

    .name-box {
      .name {
        font-size: 33px;
        font-weight: bold;
        line-height: 44px;
        color: #FFFFFF;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
      }

      .series {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        margin-bottom: 8px;
      }
    }

    .enter-btn {
      margin-left: 300px;
      width: 97px;
      height: 29px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      //background-image: url("../assets/product/dialog/enter-btn.png");
      cursor: pointer;
    }

    .view-now-btn {
      display: inline-block;
      padding: 10px 30px;
      background-color: #ff3829;
      color: #fff;
      font-size: 14px;
      margin-left: 200px;
      border-radius: 20px;
    }

  }

  .description-box {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #333333;
    margin-top: 20px;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: #333;
    }

    .content {
      margin-top: 5px;
      font-size: 14px;
      text-indent: 2em;
      font-weight: 400;
      line-height: 19px;
      color: #333333;
    }
  }

  .view-box {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;

    .video-box {
      width: 337px;
      height: 223px;
      position: relative;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 337px;
        height: 223px;
        object-fit: fill;
        z-index: 1;
      }

      .play-btn {
        position: absolute;
        // 上下左右居中
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 54px;
        height: 54px;
        cursor: pointer;
        z-index: 2;
      }
    }

    .video-box-no {
      width: 337px;
      height: 223px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
    }

    .img-box {
      width: 695px;

      .img {
        width: 334px;
        height: 106px;
        cursor: pointer;

        &:nth-child(1), &:nth-child(2) {
          margin-bottom: 11px;
        }
      }
    }
  }
}

.member-list {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 55px;
  width: 1240px;

  .el-card {
    border: none;
  }

  .avatar-list {
    .avatar-li {
      cursor: pointer;
      margin-top: 15px;
      margin-left: 34px;
      margin-right: 0px;

      .el-image {
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        opacity: 1;
        transform: scale(1);
        cursor: pointer;


        &:hover {
          transition: all 0.3s linear;
          transform: scale(1.1);
        }
      }

      .name {
        text-align: center;
        margin-top: 15px;
        color: #888;
        font-size: 15px;
      }
    }
  }
}

.member-dialog {
  .history {
    text-align: left;
    font-size: 14px;
    color: #888;
  }

  .success {
    text-align: left;
    font-size: 14px;
    color: #888;
  }
}

// 友情链接
.link-container {
  margin-bottom: 45px;

  .list {
    margin-top: 40px;
    text-align: center;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 10px;

    .name {
      margin-top: 10px;
      color: #777;
      font-size: 15px;
    }
  }


  .li {
    width: 285px;
    height: 140px;
    margin-top: 10px;
    //margin-right: 10px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transform: scale(1);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: all 0.3s linear;
      transform: scale(1.03);
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
    color: #707070;

    &:hover {
      transform: scale(1.1);
    }

    span {
      font-weight: 400;
      line-height: 21px;
      color: #707070;
      font-size: 16px;
      margin-right: 3px;
    }

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

.page-footer {
  background-color: #2d2d2d;
  padding: 20px 0px;

  .info-container {
    .logo {
      width: 70px;
      height: 70px;
      cursor: pointer;
      display: none;
    }

    .infos {
      text-align: center;
      color: @remark-color;
      font-size: 14px;

      span {
        margin-right: 10px;
      }
    }
  }
}

.back-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99999;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
